import * as React from 'react'
import { Themes } from '../../ts/enums'
import { scaleMm, toMillims } from '../../utils'
import { embossingColor, embossingFilterLight } from '../../utils/styles'

const strokeWidth = scaleMm(17)

const getDyDifference = (height: number) => {
  let scaledHeight = toMillims(height)

  if (scaledHeight < 2065) {
    return 235.13
  } else if (scaledHeight < 2165) {
    return 247.5
  } else if (scaledHeight < 2264) {
    return 259.88
  } else if (scaledHeight < 2365) {
    return 272.25
  } else {
    return 284.63
  }
}

interface IProps {
  width: number
  height: number
  theme: Themes
}

export const Embossing: React.FC<IProps> = ({ width, height, theme }) => {
  return (
    <>
      <defs>
        <line
          id="stamping"
          x1={scaleMm(310)}
          x2={width - scaleMm(129.5)}
          stroke={embossingColor[theme]}
          strokeWidth={strokeWidth}
          opacity="0.6"
        />

        <filter id="stampingFilter">
          <feGaussianBlur in="SourceAlpha" stdDeviation="1" result="blur1" />
          <feSpecularLighting
            result="specOut"
            in="blur1"
            specularConstant="1.1"
            specularExponent="30"
            lighting-color={embossingFilterLight[theme]}
          >
            <feDistantLight azimuth="680" elevation="50" />
          </feSpecularLighting>
          <feComposite
            in="SourceGraphic"
            in2="specOut"
            operator="arithmetic"
            k1="0"
            k2="1"
            k3="1"
            k4="0"
            result="result"
          />
          <feComposite operator="atop" in2="SourceGraphic" />
        </filter>
      </defs>
      <g filter="url(#stampingFilter)">
        <g
          className="svgEmbossings"
          style={{
            transform: `translateY(${height / 2 - scaleMm(getDyDifference(height) * 2 - 52.2)}px)`,
          }}
        >
          <use href="#stamping" />
          <use
            href="#stamping"
            style={{
              transform: `translateY(${scaleMm(getDyDifference(height))}px)`,
            }}
          />
          <use
            href="#stamping"
            style={{
              transform: `translateY(${scaleMm(2 * getDyDifference(height))}px)`,
            }}
          />
          <use
            href="#stamping"
            style={{
              transform: `translateY(${scaleMm(3 * getDyDifference(height))}px)`,
            }}
          />
          <use
            href="#stamping"
            style={{
              transform: `translateY(${scaleMm(4 * getDyDifference(height))}px)`,
            }}
          />
        </g>
      </g>
    </>
  )
}
