import { CSSProperties } from 'react'
import * as Types from '../ts/types'
import { createColorScheme, rgb } from '../utils'
import { Themes, Frames } from '../ts/enums'

export const frameColor: { [key in Frames]: string } = {
  [Frames.Inox]: '#AEADAD',
  [Frames.Black]: '#000000',
}

export const lightFilterColor = {
  [Themes.ZlotyDab]: '#bd8a59',
  [Themes.Wenge]: '#9c716d',
  [Themes.Szary]: 'white',
  [Themes.OrzechCiemny]: '#bd8d6c',
  [Themes.OrzechBrazylijski]: '#916b51',
  [Themes.Mahon]: '#946256',
  [Themes.Winchester]: '#e6be8c',
  [Themes.Dab]: '#a88968',
  [Themes.Antracyt]: '#a1a1a1',
  [Themes.AntracytMolet]: '#a1a1a1',
  [Themes.BialyLaminat]: 'white',
  [Themes.Bialy]: 'white',
}

export const embossingColor = {
  [Themes.ZlotyDab]: '#36261b',
  [Themes.Wenge]: '#241f1d',
  [Themes.Szary]: 'gray',
  [Themes.OrzechCiemny]: '#291e15',
  [Themes.OrzechBrazylijski]: '#291e15',
  [Themes.Mahon]: '#1c1716',
  [Themes.Winchester]: '#4d3414',
  [Themes.Dab]: '#261d15',
  [Themes.Antracyt]: 'black',
  [Themes.AntracytMolet]: 'black',
  [Themes.BialyLaminat]: '#8c8c8c',
  [Themes.Bialy]: '#8c8c8c',
}

export const embossingFilterLight = {
  [Themes.ZlotyDab]: '#e69d6c',
  [Themes.Wenge]: '#c98f77',
  [Themes.Szary]: 'white',
  [Themes.OrzechCiemny]: '#e89454',
  [Themes.OrzechBrazylijski]: '#e89454',
  [Themes.Mahon]: '#966b62',
  [Themes.Winchester]: '#d9ad75',
  [Themes.Dab]: '#a36a31',
  [Themes.Antracyt]: '#ababab',
  [Themes.AntracytMolet]: '#ababab',
  [Themes.BialyLaminat]: 'white',
  [Themes.Bialy]: 'white',
}

export const doorContainer: Types.DoorStyles = {
  outside: undefined,
  inside: {
    transform: 'translateY(-5px)',
    WebkitFilter: 'drop-shadow(rgba(255, 255, 255, 0.15) 0px 20px 6px)',
    filter: 'drop-shadow(rgba(255, 255, 255, 0.15) 0px 20px 6px)',
  },
}

export const svgContainer: CSSProperties = {
  background: 'url(patterns/witewall_3.png)',
}

export const getSVGStyles = (
  loading: boolean,
  direction: Types.DoorDirection,
  theme: Themes,
  width: number
) => {
  const getSVGBackground = () => {
    if (loading)
      return `linear-gradient(rgba(222, 232, 235, 1), rgba(115, 115, 125, 1)) repeat scroll 0% 0%, rgba(0, 0, 0, 1) url("patterns/orzech_ciemny.jpg") repeat scroll 0% 0%`
    if (direction === 'outside')
      return `linear-gradient(rgba(0,0,0,0.2), rgba(0, 0, 0, 0.2)), url("patterns/${theme}.jpg")`
    else if (direction === 'inside') {
      return 'url(patterns/floor7.jpg)'
    }
  }

  const getTransform = () => {
    let props = ''
    if (direction === 'inside') props += 'rotateY(180deg)'
    return props
  }

  const getBoxShadow = () => {
    if (loading) return 'rgba(0, 0, 0, 0.32) -1px 8.5px 22px'
    if (direction === 'outside') return 'rgba(0, 0, 0, 0.52) -1px 8.5px 18px'
    else {
    }
  }

  return {
    background: getSVGBackground(),
    opacity: loading ? 0.65 : 1,
    filter: loading ? 'blur(1px)' : '',
    left: `calc(50% - ${width / 2}px)`,
    transform: getTransform(),
    boxShadow: getBoxShadow(),
  }
}

export const computeGlazing = (loading: boolean): CSSProperties => {
  return {
    opacity: loading ? 0.4 : 1,
    strokeWidth: loading ? 5 : 1,
    transition: '0.15s opacity, 0.15s stroke-width',
  }
}

export const computeAccesories = (loading: boolean): CSSProperties => {
  return {
    opacity: loading ? 0 : 1,
    transition: '0.11s opacity',
  }
}

export const computeSideContainer = (
  width: number,
  side: Types.DoorSide
): CSSProperties | undefined => {
  const sideContainer: Types.SideContainerStyles = {
    left: { transform: `rotateY(-180deg) translateX(${-width}px)` },
    right: undefined,
  }

  return sideContainer[side]
}
