import React from 'react'
import { Slide, Tooltip, Radio, Slider, FormControlLabel } from '@material-ui/core'
import { scaleMm, toMillims } from '../utils'
import { Themes, Frames } from '../ts/enums'
import { DoorDirection, DoorSide } from '../ts/types'
import SVG from 'react-inlinesvg'

interface SettingsProps {
  show?: boolean
  direction: DoorDirection
  side: DoorSide
  width: number
  height: number
  theme: Themes
  frame: Frames
  onDirectionChange?: (event: React.ChangeEvent<HTMLInputElement>) => void
  onSideChange?: (event: React.ChangeEvent<HTMLInputElement>) => void
  onWidthChange?: (event: any, newValue: number | number[]) => void
  onHeightChange?: (event: any, newValue: number | number[]) => void
  onFrameChange?: (frame: Frames) => void
  onThemeChange?: (theme: Themes) => void
}

export const frameTooltip: { [key in Frames]: string } = {
  [Frames.Inox]: 'Ramka Inox',
  [Frames.Black]: 'Ramka Black',
}

export const themeTooltip = {
  [Themes.ZlotyDab]: 'Złoty dąb',
  [Themes.Wenge]: 'Wenge',
  [Themes.Szary]: 'Szary',
  [Themes.OrzechCiemny]: 'Orzech ciemny',
  [Themes.OrzechBrazylijski]: 'Orzech brazylijski',
  [Themes.Mahon]: 'Mahoń',
  [Themes.Winchester]: 'Winchester',
  [Themes.Dab]: 'Dąb',
  [Themes.Antracyt]: 'Antracyt',
  [Themes.AntracytMolet]: 'Antracyt molet',
  [Themes.BialyLaminat]: 'Biały laminat',
  [Themes.Bialy]: 'Biały',
}

export const SettingsBar: React.FC<SettingsProps> = ({
  show = true,
  direction,
  side,
  width,
  height,
  theme,
  frame,
  onDirectionChange,
  onSideChange,
  onWidthChange,
  onHeightChange,
  onThemeChange,
  onFrameChange,
}) => {
  return (
    <Slide
      direction="up"
      in={show}
      style={{ transformOrigin: '0 0 0' }}
      {...(show ? { timeout: 1250 } : {})}
    >
      <div className="settings min-h-full">
        <div className="setting-header text-lg font-light px-8 py-4 w-full text-left">
          KOLOR DRZWI
          <span className="text-gray-400"> - {themeTooltip[theme]}</span>
        </div>
        <div className="flex flex-wrap palette my-8 px-8">
          {Object.entries(Themes).map(([k, v]) => {
            return (
              <Tooltip key={k} title={themeTooltip[v]}>
                <img
                  onClick={() => {
                    if (onThemeChange) {
                      onThemeChange(v as Themes)
                    }
                  }}
                  src={`palette/${v}.jpg`}
                  width="40"
                  height="40"
                  loading="lazy"
                  style={{
                    opacity: v === theme ? 1 : 0.68,
                    border:
                      v === theme ? '1px solid rgba(0,0,0,0.01)' : '1px solid rgba(0, 0, 0, 0.4)',
                  }}
                />
              </Tooltip>
            )
          })}
        </div>
        <div className="setting-header text-lg font-light px-8 py-4 w-full text-left">
          KOLOR RAMKI
          <span className="text-gray-400"> - {frameTooltip[frame]}</span>
        </div>
        <div className="flex flex-wrap palette my-8 px-8">
          {Object.entries(Frames).map(([k, v]) => {
            return (
              <Tooltip key={k} title={frameTooltip[v]}>
                <img
                  onClick={() => {
                    if (onFrameChange) {
                      onFrameChange(v)
                    }
                  }}
                  src={`palette/${v}.jpg`}
                  width="40"
                  height="40"
                  loading="lazy"
                  style={{
                    opacity: v === frame ? 1 : 0.68,
                    border:
                      v === frame ? '1px solid rgba(0,0,0,0.01)' : '1px solid rgba(0, 0, 0, 0.4)',
                  }}
                />
              </Tooltip>
            )
          })}
        </div>
        <div className="setting-header text-lg font-light px-8 py-4 w-full text-left">
          STRONA I KIERUNEK OTWIERANIA
        </div>
        <div className="px-10 my-8">
          <div className="flex items-center justify-between pr-8">
            <div className="flex flex-col">
              <div>
                <FormControlLabel
                  value="inside"
                  control={
                    <Radio
                      onChange={onDirectionChange}
                      checked={direction === 'inside'}
                      color="primary"
                    />
                  }
                  label="Do wewnątrz"
                />
              </div>

              <div>
                <FormControlLabel
                  value="left"
                  control={
                    <Radio onChange={onSideChange} checked={side === 'left'} color="secondary" />
                  }
                  label="Lewe"
                />
              </div>
            </div>

            <div>
              <div className="flex flex-col">
                <div>
                  <FormControlLabel
                    value="outside"
                    label="Na zewnątrz"
                    control={
                      <Radio
                        onChange={onDirectionChange}
                        checked={direction === 'outside'}
                        color="primary"
                      />
                    }
                  />
                </div>

                <div>
                  <FormControlLabel
                    value="right"
                    label="Prawe"
                    control={
                      <Radio
                        value="right"
                        onChange={onSideChange}
                        checked={side === 'right'}
                        color="secondary"
                      />
                    }
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="setting-header text-lg font-light px-8 py-4 w-full text-left">WYMIARY</div>
        <div className="dimensions px-12 my-8">
          <div className="flex flex-col">
            <span className="standard-font">Wysokość:</span>
            <Slider
              value={height}
              step={1}
              min={scaleMm(1965)}
              max={scaleMm(2464)}
              valueLabelDisplay="auto"
              scale={height => toMillims(height)}
              onChange={onHeightChange}
            />
          </div>

          <div className="flex flex-col">
            <span className="standard-font">Szerokość:</span>
            <Slider
              color="secondary"
              value={width}
              step={1}
              min={scaleMm(811)}
              max={scaleMm(1179)}
              valueLabelDisplay="auto"
              scale={width => toMillims(width)}
              onChange={onWidthChange}
            />
          </div>
        </div>
        <a
          href="http://akanza.pl"
          target="_blank"
          rel="noreferrer"
          className="flex justify-center w-full items-center rounded-sm rounded-b-none rounded-r-none cursor-pointer transition-all py-2 px-4 z-10 right-0 bottom-0"
        >
          <SVG width="250" id="martom" src="images/by_akanza.svg" />
        </a>
      </div>
    </Slide>
  )
}
