import { STANDARD_GLAZING_HEIGHT } from '../ts/constants'

export let SCALE_RATIO = 3.25

export const updateScaleRatio = (newScale: number) => {
  SCALE_RATIO = newScale
}

export const rgb = (r: number, g: number, b: number) => {
  return `rgb(${r},${g},${b})`
}

export const createColorScheme = (primary: string, secondary: string) => {
  return {
    primary,
    secondary,
  }
}

export const getGlazingHeight = (frameHeight: number) => {
  if (frameHeight < 2065) return STANDARD_GLAZING_HEIGHT - 100
  if (frameHeight < 2165) return STANDARD_GLAZING_HEIGHT
  if (frameHeight < 2265) return STANDARD_GLAZING_HEIGHT + 100
  if (frameHeight < 2365) return STANDARD_GLAZING_HEIGHT + 200
  return STANDARD_GLAZING_HEIGHT + 300
}

export const getStampingDifference = (frameHeight: number) => {
  if (frameHeight < 2065) return 483
  if (frameHeight < 2165) return 533
  if (frameHeight < 2265) return 583
  if (frameHeight < 2365) return 633
  return 683
}

export const scaleMm = (millimeters: number) => {
  // return Math.round((millimeters / SCALE_RATIO) * 10) / 10
  return millimeters / SCALE_RATIO
}

export const toMillims = (px: number) => {
  // return Math.round(px * SCALE_RATIO * 10) / 10
  return px * SCALE_RATIO
}

export const getFramePoints = (width: number, height: number, offset: number) => {
  return `${width - scaleMm(offset)},${height} ${width - scaleMm(offset)}, ${scaleMm(
    offset
  )} ${scaleMm(offset)},${scaleMm(offset)} ${scaleMm(offset)},${height}`
}
