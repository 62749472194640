import React from 'react'
import { scaleMm } from '../utils'
import { FRAME_HORIZONTAL_GAP, FRAME_VERTICAL_GAP } from '../ts/constants'

interface HingesProps {
  width: number
  height: number
}

export const Hinges: React.FC<HingesProps> = ({ width, height }) => {
  const HINGE_HEIGHT = scaleMm(140)
  const HINGE_WIDTH = scaleMm(10.8)

  return (
    <>
      <defs>
        <filter id="hingeSpotlight">
          <feDiffuseLighting in="SourceGraphic" result="light" lighting-color="white">
            <fePointLight x={0} y={height / 5} z="160" />
          </feDiffuseLighting>

          <feComposite
            in="SourceGraphic"
            in2="light"
            operator="arithmetic"
            k1="1"
            k2="0"
            k3="0"
            k4="0"
          />
        </filter>
      </defs>
      <g>
        <rect
          className="hinge"
          fill="#ededed"
          width={HINGE_WIDTH}
          height={HINGE_HEIGHT}
          y={scaleMm(FRAME_VERTICAL_GAP) + scaleMm(178.8)}
          x={width - scaleMm(FRAME_HORIZONTAL_GAP)}
        ></rect>
        <line
          stroke="rgba(0,0,0,0.55)"
          x1={width - scaleMm(FRAME_VERTICAL_GAP)}
          x2={width - scaleMm(FRAME_HORIZONTAL_GAP) + HINGE_WIDTH}
          y1={scaleMm(FRAME_VERTICAL_GAP) + scaleMm(178.8) + HINGE_HEIGHT / 2}
          y2={scaleMm(FRAME_VERTICAL_GAP) + scaleMm(178.8) + HINGE_HEIGHT / 2}
        ></line>
        <rect
          className="hinge"
          fill="#ededed"
          width={HINGE_WIDTH}
          height={HINGE_HEIGHT}
          y={scaleMm(FRAME_VERTICAL_GAP) + scaleMm(766.1)}
          x={width - scaleMm(FRAME_HORIZONTAL_GAP)}
        ></rect>
        <line
          stroke="rgba(0,0,0,0.55)"
          x1={width - scaleMm(FRAME_VERTICAL_GAP)}
          x2={width - scaleMm(FRAME_HORIZONTAL_GAP) + HINGE_WIDTH}
          y1={scaleMm(FRAME_VERTICAL_GAP) + scaleMm(766.1) + HINGE_HEIGHT / 2}
          y2={scaleMm(FRAME_VERTICAL_GAP) + scaleMm(766.1) + HINGE_HEIGHT / 2}
        ></line>
        <rect
          className="hinge"
          fill="#ededed"
          width={HINGE_WIDTH}
          height={HINGE_HEIGHT}
          y={height - HINGE_HEIGHT - 2 - scaleMm(205.1)}
          x={width - scaleMm(FRAME_HORIZONTAL_GAP)}
        ></rect>
        <line
          stroke="rgba(0,0,0,0.55)"
          x1={width - scaleMm(FRAME_VERTICAL_GAP)}
          x2={width - scaleMm(FRAME_HORIZONTAL_GAP) + HINGE_WIDTH}
          y1={height - scaleMm(205.1) - HINGE_HEIGHT / 2}
          y2={height - scaleMm(205.1) - HINGE_HEIGHT / 2}
        ></line>
      </g>
    </>
  )
}
