export enum Themes {
  Winchester = 'winchester',
  Dab = 'dab_bagienny',
  Antracyt = 'antracyt',
  AntracytMolet = 'antracyt_molet',
  BialyLaminat = 'bialy_laminat',
  Bialy = 'bialy',
  Mahon = 'mahon',
  OrzechBrazylijski = 'orzech_brazylijski',
  OrzechCiemny = 'orzech_ciemny',
  Szary = 'szary',
  Wenge = 'wenge',
  ZlotyDab = 'zloty_dab',
}

export enum Frames {
  Inox = 'inox',
  Black = 'black',
}

export enum FrameDimensions {
  StrokeWidth = 60,
  Offset = FrameDimensions.StrokeWidth / 2,
}
