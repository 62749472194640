import React from 'react'

interface Dimensions {
  width: number
  height: number
}

export const useWindowSize = () => {
  const [size, setSize] = React.useState<number[]>([0, 0])

  React.useLayoutEffect(() => {
    const updateSize = () => {
      setSize([window.innerWidth, window.innerHeight])
    }

    window.addEventListener('resize', updateSize)
    updateSize()
    return () => window.removeEventListener('resize', updateSize)
  }, [])

  return size
}
