import { ZoomIn, ZoomOut } from '@material-ui/icons'
import { Zoom } from '../ts/types'
import React from 'react'
import { Fade, Grow } from '@material-ui/core'

interface ZoomControlsProps {
  inDisabled?: boolean
  outDisabled?: boolean
  onZoom: (type: Zoom) => void
}

const styles = 'cursor-pointer hover:text-indigo-900 transition'

export const ZoomControls: React.FC<ZoomControlsProps> = ({ inDisabled, outDisabled, onZoom }) => {
  const [rendered, setRendered] = React.useState<boolean>(false)

  React.useEffect(() => {
    setRendered(true)
  }, [])

  return (
    <>
      <Fade in={rendered} {...(rendered ? { timeout: 2250 } : {})}>
        <div className="flex zoom-icons">
          <div
            className={inDisabled ? 'inactive' : styles}
            onClick={() => {
              onZoom('in')
            }}
          >
            <ZoomIn fontSize="large" />
          </div>
          <div
            className={outDisabled ? 'inactive' : styles}
            onClick={() => {
              onZoom('out')
            }}
          >
            <ZoomOut fontSize="large" />
          </div>
        </div>
      </Fade>
    </>
  )
}
