import React from 'react'
import { DoorPanel } from './components/DoorPanel'
import SVG from 'react-inlinesvg'

export const MainLayout: React.FC = () => {
  return (
    <div className="mx-auto h-screen">
      <a
        href="http://drzwimartom.pl"
        target="_blank"
        rel="noreferrer"
        className="absolute cursor-pointer z-10 right-0 m-2"
      >
        <img width="170" alt="martom-logo" id="martom" src="images/martom.png" />
      </a>

      <DoorPanel />
    </div>
  )
}
