import React from 'react'
import * as Styles from '../utils/styles'
import * as Constants from '../ts/constants'
import * as Types from '../ts/types'
import { Themes, Frames, FrameDimensions } from '../ts/enums'
import { scaleMm, updateScaleRatio, SCALE_RATIO, getFramePoints } from '../utils'
import { ZoomControls, TextureLoader, SettingsBar, LoadingScreen, SVGDoors } from './index'
import { useWindowSize } from '../hooks/useWindowSize'
import { F15Svg } from '../models/F15/F15Svg'
import { Hinges } from './Hinges'
import { DimensionMarkers } from './DimensionMarkers'

export const DoorPanel: React.FC = () => {
  const [direction, setDirection] = React.useState<Types.DoorDirection>('outside')
  const [side, setSide] = React.useState<Types.DoorSide>('left')
  const [width, setWidth] = React.useState<number>(scaleMm(1049))
  const [height, setHeight] = React.useState<number>(scaleMm(2115))
  const [frame, setFrame] = React.useState<Frames>(Frames.Black)
  const [theme, setTheme] = React.useState<Themes>(Themes.OrzechCiemny)
  const [initPatternLoading, setInitPatternLoading] = React.useState<boolean>(true)
  const [zoom, setZoom] = React.useState<number>(1.0)
  const [loading, setloading] = React.useState<boolean>(false)
  const size = useWindowSize()

  const handleZoom = (e: any) => {
    if (e.deltaY < 0) {
      if (zoom < Constants.MAX_ZOOM) setZoom(zoom + 0.1)
    } else {
      if (zoom > 1) setZoom(zoom - 0.1)
    }
  }

  const trackRwd = () => {
    const height = size[1]

    if (height > 780 || height === 0) {
      updateScaleRatio(3.25)
    } else if (height >= 700 && height <= 780) {
      updateScaleRatio(3.8)
    } else if (height < 700 && height > 500) {
      updateScaleRatio(5.0)
    } else {
      updateScaleRatio(7.0)
    }
  }

  const updateDimensions = () => {
    setWidth(scaleMm(1049))
    setHeight(scaleMm(2115))
  }

  React.useEffect(() => {
    trackRwd()
    updateDimensions()
  }, [size])

  React.useEffect(() => {
    updateDimensions()
  }, [SCALE_RATIO])

  const changeWidth = (event: any, newValue: number | number[]) => {
    setWidth(newValue as number)
  }

  const changeHeight = (event: any, newValue: number | number[]) => {
    setHeight(newValue as number)
  }

  const changeDirection = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDirection(event.target.value as Types.DoorDirection)
  }

  const changeTheme = (newTheme: Themes) => {
    if (theme !== newTheme) {
      setloading(true)

      setTimeout(() => {
        setTheme(newTheme)
      }, 250)
    }
  }

  const changeFrame = (frame: Frames) => {
    setFrame(frame)
  }

  const changeSide = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSide(event.target.value as Types.DoorSide)
  }

  const changeZoom = (type: Types.Zoom) => {
    if (type === 'in') setZoom(zoom + 0.1)
    else setZoom(zoom - 0.1)
  }

  const handleImageLoaded = () => {
    if (!initPatternLoading) {
      setTimeout(() => {
        setloading(false)
      }, 250)
    }

    setTimeout(() => {
      setInitPatternLoading(false)
    }, 100)
  }

  return (
    <>
      {/* <LoadingScreen show={initPatternLoading} /> */}
      <SettingsBar
        // show={!initPatternLoading}
        direction={direction}
        width={width}
        height={height}
        theme={theme}
        frame={frame}
        side={side}
        onSideChange={changeSide}
        onDirectionChange={changeDirection}
        onWidthChange={changeWidth}
        onHeightChange={changeHeight}
        onThemeChange={changeTheme}
        onFrameChange={changeFrame}
      />
      <ZoomControls
        onZoom={changeZoom}
        inDisabled={zoom >= Constants.MAX_ZOOM}
        outDisabled={zoom <= 1}
      />

      <div
        style={{
          transform: `scale(${zoom})`,
          transition: '0.17s transform',
        }}
      >
        <div
          className="absolute floor"
          style={{
            background: 'url(patterns/floor8.jpg) ',
            backgroundSize: '170px auto',
            transition: '2.15s cubic-bezier(0.15, 0.25, 0.25, 1) background-position-x',
          }}
        ></div>
        <div className="relative svg-container" style={Styles.svgContainer} onWheel={handleZoom}>
          <div
            className="relative border-b border-gray-400"
            style={{
              height: scaleMm(Constants.MAX_HEIGHT + 225),
            }}
          >
            <svg
              className="absolute svg-box mx-auto"
              width={width}
              height={height}
              style={{
                left: `calc(50% - ${width / 2}px)`,
              }}
            >
              <defs>
                <pattern
                  width={Constants.MAX_WIDTH}
                  height={Constants.MAX_HEIGHT}
                  preserveAspectRatio="none"
                  patternUnits="userSpaceOnUse"
                  id="oscieznica"
                >
                  <image
                    x="-15"
                    href={`patterns/${theme}.jpg`}
                    width={scaleMm(Constants.MAX_WIDTH)}
                    height={scaleMm(Constants.MAX_HEIGHT)}
                  />
                </pattern>

                <filter id="frameOutside">
                  <feSpecularLighting
                    result="specOut"
                    specularExponent="12.5"
                    lighting-color={Styles.lightFilterColor[theme]}
                  >
                    <fePointLight x={-140} y={height / 2.8} z="140" />
                  </feSpecularLighting>
                  <feComposite
                    in="SourceGraphic"
                    in2="specOut"
                    operator="arithmetic"
                    k1="0"
                    k2="1"
                    k3="1"
                    k4="0"
                  />
                </filter>

                <filter id="frameInside">
                  <feComposite in="light" in2="SourceAlpha" operator="in" result="lit" />

                  <feGaussianBlur in="SourceAlpha" stdDeviation="10" />
                  <feOffset dx="0" dy="4" result="blur" />

                  <feComposite in="blur" in2="SourceAlpha" operator="out" result="shadow" />

                  <feComposite in="shadow" in2="lit" operator="xor" />
                </filter>

                <clipPath id="clip">
                  <circle
                    cx={-300}
                    cy={height / 2}
                    r={500}
                    strokeWidth="40"
                    fill="none"
                    stroke="gray"
                  />
                </clipPath>

                <pattern
                  id="doorTheme"
                  preserveAspectRatio="none"
                  patternUnits="userSpaceOnUse"
                  width={scaleMm(Constants.MAX_WIDTH)}
                  height={scaleMm(Constants.MAX_HEIGHT)}
                >
                  <image
                    href={`patterns/${theme}.jpg`}
                    width={scaleMm(Constants.MAX_WIDTH)}
                    height={scaleMm(Constants.MAX_HEIGHT)}
                  />
                </pattern>
              </defs>

              <g
                style={{
                  transform:
                    direction === 'inside' ? `rotateY(-180deg) translateX(${-width}px)` : '',
                }}
              >
                <g
                  style={{
                    transform: side === 'left' ? `rotateY(-180deg) translateX(${-width}px)` : '',
                  }}
                >
                  <g
                    style={{
                      transform: `translateX(${scaleMm(49.5)}px) translateY(${scaleMm(52.2)}px)`,
                    }}
                  >
                    <rect
                      width={width - 2 * scaleMm(49.0)}
                      height={height - scaleMm(52.2)}
                      fill="url(#doorTheme)"
                    />
                    <F15Svg theme={theme} frame={frame} width={width} height={height} />
                  </g>

                  <g
                    fill="url(#doorTheme)"
                    filter={`url(#${direction === 'inside' ? 'frameInside' : 'frameOutside'})`}
                  >
                    <rect width={scaleMm(49.5)} height={height} />
                    <rect width={scaleMm(49.5)} height={height} x={width - scaleMm(49.5)} />
                    <rect width={width} height={scaleMm(52.2)} x={0} />
                    <line
                      x1={0}
                      x2={scaleMm(49.5)}
                      y1={0}
                      y2={scaleMm(52.2)}
                      stroke="black"
                      strokeWidth="1.5"
                    />
                    <line
                      x1={width}
                      x2={width - scaleMm(49.5)}
                      y1={0}
                      y2={scaleMm(52.2)}
                      stroke="black"
                      strokeWidth="1"
                      strokeOpacity="0.75"
                    />
                  </g>

                  {direction === 'outside' && <Hinges width={width} height={height} />}
                </g>
              </g>
            </svg>

            {/* Pointers etc. here */}
            <DimensionMarkers width={width} height={height} />
          </div>
        </div>
      </div>
    </>
  )
}
