import { SCALE_RATIO } from '../../utils'

const DEFAULT_FRAME_RADIUS = 2478
const DEFAULT_FRAME_CX = 2764

const DEFAULT_GLAZING_RADIUS = 1172.5
const DEFAULT_GLAZING_CX = -585

export const getGlazingRadius = (height: number) => {
  let scaledHeight = height * SCALE_RATIO

  if (scaledHeight < 2065) {
    return DEFAULT_GLAZING_RADIUS * 0.95
  } else if (scaledHeight < 2165) {
    return DEFAULT_GLAZING_RADIUS
  } else if (scaledHeight < 2264) {
    return DEFAULT_GLAZING_RADIUS * 1.05
  } else if (scaledHeight < 2365) {
    return DEFAULT_GLAZING_RADIUS * 1.1
  } else {
    return DEFAULT_GLAZING_RADIUS * 1.15
  }
}

export const getFrameRadius = (height: number) => {
  let scaledHeight = height * SCALE_RATIO

  if (scaledHeight < 2065) {
    return DEFAULT_FRAME_RADIUS * 0.95
  } else if (scaledHeight < 2165) {
    return DEFAULT_FRAME_RADIUS
  } else if (scaledHeight < 2264) {
    return DEFAULT_FRAME_RADIUS * 1.05
  } else if (scaledHeight < 2365) {
    return DEFAULT_FRAME_RADIUS * 1.1
  } else {
    return DEFAULT_FRAME_RADIUS * 1.15
  }
}

export const getFrameCx = (height: number) => {
  let scaledHeight = height * SCALE_RATIO

  if (scaledHeight < 2065) {
    return DEFAULT_FRAME_CX - 123.5
  } else if (scaledHeight < 2165) {
    return DEFAULT_FRAME_CX
  } else if (scaledHeight < 2264) {
    return DEFAULT_FRAME_CX + 123.5
  } else if (scaledHeight < 2365) {
    return DEFAULT_FRAME_CX + 247
  } else {
    return DEFAULT_FRAME_CX + 370.5
  }
}

export const getGlazingCx = (height: number) => {
  let scaledHeight = height * SCALE_RATIO

  if (scaledHeight < 2065) {
    return -545
  } else if (scaledHeight < 2165) {
    return DEFAULT_GLAZING_CX
  } else if (scaledHeight < 2264) {
    return -636
  } else if (scaledHeight < 2365) {
    return -683
  } else {
    return -727
  }
}
