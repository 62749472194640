import React from 'react'
import { SCALE_RATIO } from '../utils'

interface DimensionProps {
  width: number
  height: number
}

export const DimensionMarkers: React.FC<DimensionProps> = ({ width, height }) => {
  return (
    <svg
      className="absolute svg-box mx-auto"
      overflow="visible"
      width={width}
      height={height}
      style={{
        left: `calc(50% - ${width / 2}px)`,
      }}
    >
      <line x1="0" y1="-15" x2="5" y2="-20" stroke="black" />
      <line x1="0" y1="-15" x2="5" y2="-10" stroke="black" />
      <line x1="0" y1="-15" x2={width} y2="-15" stroke="black" />
      <line x1={width} y1="-15" x2={width - 5} y2="-20" stroke="black" />
      <line x1={width} y1="-15" x2={width - 5} y2="-10" stroke="black" />
      <text x={width / 2 - 25} y="-20">
        {Math.round(width * SCALE_RATIO * 100) / 100}mm
      </text>

      <line x1={width + 15} x2={width + 20} y1={height} y2={height - 5} stroke="black" />
      <line x1={width + 10} x2={width + 15} y1={height - 5} y2={height} stroke="black" />
      <line x1={width + 10} x2={width + 15} y1={5} y2={0} stroke="black" />
      <line x1={width + 20} x2={width + 15} y1={5} y2={0} stroke="black" />
      <line x1={width + 15} x2={width + 15} y1={0} y2={height} stroke="black" />

      <text
        x={height / 2 - 30}
        y={-width - 20}
        textAnchor="start"
        style={{
          transform: 'rotateZ(90deg)',
        }}
      >
        {Math.round(height * SCALE_RATIO * 100) / 100}mm
      </text>
    </svg>
  )
}
