import * as React from 'react'
import { Embossing } from './Embossing'
import { scaleMm } from '../../utils'
import { getFrameRadius, getGlazingRadius, getFrameCx, getGlazingCx } from './config'
import { Frames, Themes } from '../../ts/enums'

interface ModelProps {
  width: number
  height: number
  frame: Frames
  theme: Themes
}

export const F15Svg: React.FC<ModelProps> = ({ width, height, frame, theme }) => {
  const frameColor = frame === 'inox' ? '#A0A0A0' : 'black'

  return (
    <>
      <defs>
        <circle
          id="frame"
          cx={scaleMm(getFrameCx(height))}
          cy={height / 2 + scaleMm(26.1)}
          r={scaleMm(getFrameRadius(height))}
          strokeWidth={scaleMm(80)}
          style={{
            // @ts-ignore
            r: scaleMm(getFrameRadius(height)),
            transition: '0.25s all',
          }}
        />

        <circle
          id="glazing"
          cx={scaleMm(getGlazingCx(height))} //
          cy={height / 2 + scaleMm(26.1)}
          strokeWidth={scaleMm(44)}
          style={{
            // @ts-ignore
            r: scaleMm(getGlazingRadius(height)),
            transition: '0.35s all',
          }}
        />

        <clipPath id="clipCircle">
          <use href="#frame" />
        </clipPath>

        <pattern
          preserveAspectRatio="none"
          patternUnits="userSpaceOnUse"
          id="lake"
          width={width}
          height={height}
        >
          <image x="30" y="-40" href="images/lake.jpg" width={790} height={790} />
        </pattern>

        <filter id="shadow">
          <feDropShadow dx="-2" dy="10" stdDeviation="8.5" />
        </filter>

        <filter id="glazingF15">
          <feGaussianBlur in="SourceAlpha" stdDeviation="1.0" result="blur1" />
          <feSpecularLighting
            result="specOut"
            in="blur1"
            specularExponent="24.5"
            lighting-color="#ffffff"
          >
            <fePointLight x={scaleMm(440)} y={height / 2.35 + scaleMm(100)} z="45" />
          </feSpecularLighting>
          <feComposite
            in="SourceGraphic"
            in2="specOut"
            operator="arithmetic"
            k1="0"
            k2="1"
            k3="1"
            k4="0"
          />
        </filter>
      </defs>

      <svg>
        <Embossing theme={theme} width={width} height={height} />

        <g filter="url(#glazingF15)">
          <use href="#glazing" stroke={frameColor} fill="url(#lake)" clipPath="url(#clipCircle)" />
          <use href="#frame" fill="none" stroke={frameColor} />
        </g>

        <image
          href="images/pullhandle.png"
          x={scaleMm(-441)}
          y={height - scaleMm(1680)}
          height={scaleMm(1300)}
          filter="url(#shadow)"
          style={{
            transform: 'rotateY(45deg)',
          }}
        />
      </svg>
    </>
  )
}
